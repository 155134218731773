<template>
    <div class="concat">
        <div class="square">
            <div class="avatar">
                <p class="img bg-img" :style="{'background-image':'url('+data.avatar+')'}"></p>
                <h3 class="fs20">{{data.title}}</h3>
                <p class="fs14">{{data.subTitle}}</p>
            </div>
            <div class="content">
                <dl>
                    <dd class="name">客服</dd><dd class="phone">手机 (可拨打)</dd><dd class="email">邮箱</dd>
                </dl>
                <dl v-for="(item,i) in data.info" :key="i">
                    <dd class="name">{{item.name}}</dd>
                    <dd class="phone"><a class="blue-link" :href="'tel:'+item.phone" target="_blank">{{item.phone}}</a></dd>
                    <dd class="email">{{item.email}}</dd>
                </dl>
                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:["data"]
}
</script>
<style scoped lang="less">
.concat{
    margin-top:50px;
    .square{
        padding:90px 0 50px 0;
        background:#f9f9f9;
        position: relative;
        .content{
            margin:0 auto;
            width:90%;
            padding:150px 0 20px 0;
            border:4px double #d9d9d9;
            position:relative;
            dl:first-child{
                border-top:none;
                dd{
                    color:#666;
                }
            }
            dl{
                margin:0 5%;
                border-top:1px dotted #d9d9d9;
                display: flex;
                justify-content: space-between;
                dd{
                    line-height: 40px;
                    font-size:12px;
                    text-align: center;

                }
                .name{
                    width:3rem;
                }
                .phone{
                    width:40%;
                }
                .email{
                    flex:1;
                }

            }
            
        }
        .avatar{
            position: absolute;
            z-index: 2;
            margin:0 auto;
            text-align: center;
            width:200px;
            top:30px;
            left:calc(50% - 100px);
            .img{
                display: inline-block;
                width:100px;
                height:100px;
            }
            
            h3{
                margin:15px 0 5px 0;
            }
        }
    }
}
</style>