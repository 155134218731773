<template>
    <div class="service bg-img" :style="{'background-image':'url('+data[0].image+')'}">
        <div class="main">
            <div class="unit " v-for="(item,i) in data" :key="i"  @click="$router.push(item.path)">
                <dl>
                    <dd  class="icon"><Icons :name="item.icon" height="25" width="25" color="#0071e3"/></dd>
                    <dd class="fs16 title">{{item.title}}</dd>
                    <dd class="fs12">{{item.subTitle}}</dd>
                    <dd v-if="false"><span v-for="(child,j) in item.children" :key="j" class="fs14">{{child}} </span></dd>
                </dl>
            </div>
        </div>
    </div>
</template>
<script>
import Icons from '@/components/common/Icons.vue'
export default {
    components:{
        Icons
    },
    props:["data"]
}
</script>
<style scoped lang="less">
.service{
    .main{
        margin:0 auto;
        margin-top:50px;
        width:250px;
        padding:40px 0 20px 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .unit{
            width:120px;
            height:120px;
            background-color:rgba(255,255,255,0.9);
            text-align: center;
            margin-bottom:10px;
            dl{
                cursor: pointer;
                padding:20px;
                
                .icon{
                    img{
                        width:20px;
                        height:20px;
                    }

                }
                
                .title{
                    margin:10px 0 0 0;
                }
            }
            
        }
    }
}
</style>