<template>
    <div class="slogan">
        <div class="left">
            <h1 class="fs30" v-html="activeItem.title"></h1>
            <h2 class="fs20"  v-html="activeItem.subTitle"></h2>
            <p><a class="blue-btn" href="mqqwpa://im/chat?chat_type=wpa&uin=1771548541&version=1&src_type=web" target="_blank">在线咨询</a></p>
        </div>
        <div class="right">
            <p class="list-txt" v-html="activeItem.desc"></p>
        </div>
    </div>
</template>
<script>
import {EventBus} from '@/bus/index.js'
export default {
    props:['data'],
    data(){
        return {
            activeIdx:0,
            activeItem:null,
        }
    },
    created(){
        this.setActiveItem();
    },
    mounted(){
        EventBus.$on('headToggle',(idx)=>{
            if(idx!==this.activeIdx){
                this.activeIdx=idx;
                this.setActiveItem();
            }
        })
    },
    methods:{
        setActiveItem(){
            if(this.data instanceof Array){
                this.activeItem=this.data[this.activeIdx];
            }else{
                this.activeItem=this.data;
            }
        }
    }

}
</script>
<style scoped lang="less">
.slogan{
    width:90%;
    padding:0 5%;
    margin-top:50px;
    .left{
        margin-bottom:30px;

        h2{
            margin:10px 0 20px 0;
            
        }
    }
    .right{
        flex:1;
    }
}
</style>