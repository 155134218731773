<template>
    <div class="message bg-img" :style="{'background-image':'url('+data.img+')'}">
        <h1 class="fs30">{{data.title}}</h1>
        <p class="fs20">{{data.subTitle}}</p>
        <div class="form">
            <p class="line line1">
                <input type="text" class="name ipt" :placeholder="data.placeholder.name" v-model="entity.msg_name"/>
            </p>
            <p class="line line1">
                <input type="text" class="email ipt" :placeholder="data.placeholder.concat" v-model="entity.msg_contact"/>
            </p>
            <p class="line">
                <textarea class="tarea" rows="5" :placeholder="data.placeholder.message" v-model="entity.msg_text"></textarea>
            </p>
            <p class="line line3">
                <input type="button" class="blue-btn" :value="data.btn" @click="submit"/>
            </p>
        </div>
    </div>
</template>
<script>
import {postMsg} from '@/utils/http.js'
export default {
    props:["data"],
    data(){
        return {
            entity:{
                msg_name:"",
                msg_contact:"",
                msg_text:"",
            }
        }
    },
    created(){
        
    },
    methods:{
        submit(){
            if(this.entity.msg_name=="" || this.entity.msg_contact=="" || this.entity.msg_text==""){
                alert("请填写完整（名称、联系方式和想说的话）。");
                return;
            }
            postMsg(this.entity,(res)=>{
                if(res.errCode==0){
                    alert("提交成功！");
                }else{
                    alert("提交失败！");
                }
            });
        }
    }
}
</script>
<style scoped lang="less">
.message{
    padding:50px 0;
    h1{
        text-align: center;
    }
    >p{
        margin:5px 0 30px 0;
        text-align: center;
    }
    .form{
        margin:0 10%;
        background:rgba(255,255,255,0.3);
        padding:30px;
        .line1{
            display:flex;
            justify-content: space-between;
            margin-bottom:15px;
            .ipt{
                width:100%;
                height:30px;
                padding:0 5px;
                font-size:12px;
                background:white;
                border:none;
            }
        }
        .tarea{
            width:calc(100% - 10px);
            padding:5px;
            font-size:12px;
            border:none;
            background: #f9f9f9;
        }
        .line3{
            text-align: center;
            margin-top:20px;
            .btn{
                width:100px;
                height:30px;
                font-size:12px;
                color:white;
                border:none;
                
            }
        }

    }
}
</style>