<template>
    <div class="feature">
        <div class="main">
            <div class="outer bg-img" v-for="(unit,i) in data" :key="i"  :style="{'background-image':'url('+unit.img+')'}">
                <div class="square text">
                    <div class="unit">
                        <h3 class="fs20">{{unit.title}}</h3>
                        <h4 class="fs14">{{unit.subTitle}}</h4>
                        <p v-for="(option,j) in unit.options" :key="j"  class="list-txt">{{option}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:["data"],
    data(){
        return {
         
        }
    },
    created(){
       
    },
    methods:{
    }
}
</script>
<style scoped lang="less">
.feature{
    .main{
        padding-top:50px;
        
        .outer{
            height:150px;
            position: relative;
            margin-bottom:120px;
        }
        .square{
            position: absolute;
            width:80%;
            margin:0 10%;
            z-index: 2;
            top:80px;
            left:0;
            .unit{
                text-align: center;
                background:-webkit-linear-gradient(bottom,rgba(255,255,255,0),rgba(255,255,255,0.7));
                padding:15px 5%;
                h4{
                    margin:5px 0 15px 0;
                    font-weight: normal;
                }
                *{
                    padding:0 10px;
                }
            }
        }
    }
}
</style>