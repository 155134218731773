<template>
    <div class="module-head">
        
        <h1 class="fs30">{{title}}</h1>
        <h2 class="fs20">{{subTitle}}</h2>
        <p class="list-txt" v-html="desc"></p>
    </div>
</template>
<script>
export default {
    props:["data"],
    data(){
        return {
            dft:{
                title:"title",
                subTitle:"sub title",
                desc:"description"
            }
        }
    },
    computed:{
        title(){
            return this.data && this.data.title || this.dft.title;
        },
        subTitle(){
            return this.data && this.data.subTitle || this.dft.subTitle;
        },
        desc(){
            return this.data && this.data.desc || this.dft.desc;
        }
    }
}
</script>
<style scoped lang="less">
.module-head{
    margin-top:50px;
    width:90%;
    padding:0 5%;
    h2{
        font-weight: normal;
        margin:5px 0 20px 0;
    }
}
</style>