<template>
    <div class="case">
        <div class="unit" v-for="(item,i) in data" :key="i">
            <dl>
                <dt class="bg-img" :style="{'background-image':'url('+item.img+')'}"></dt>
                <dd>
                    <p class="tag">{{item.tag}}</p>
                    <p class="name">{{item.name}}</p>
                    <p class="account" v-if="item.account">{{item.account}}</p>
                </dd>
            </dl>
        </div>
    </div>
</template>
<script>

export default {
    props:["data"],
    data(){
        return {

        }
    }
}
</script>
<style scoped lang="less">
.case{
    padding-top:50px;
    
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .unit{
        width:50%;
        margin-bottom:50px;
    }
    dl{
        width:120px;
        height:120px;
        margin:0 auto;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        dd{
            position: absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            text-align: center;
            .tag{
                margin-top:30px;
            }
        }
        dt{
            width:100%;
            height:100%;
            img{
                width:100%;
                height:100%;
            }
        }
    }
}
</style>