<template>
    <div class="home">
        
        <Menu :data="homeData.service"/>

        <Head :data="homeData.head"/> 
        
        <Slogan :data="homeData.slogan"/>
        
        <Service :data="homeData.service"/>
        
        <ModuleHead :data="homeData.process.header"/>
        <Process :data="homeData.process.items"/>
        
        <!--
        <ModuleHead :data="homeData.cases.header"/>
        <Cases :data="homeData.cases.items"/>
        <Employee />
        -->
        
        <ModuleHead :data="homeData.feature.header"/>
        <Feature :data="homeData.feature.items"/>

        
        <Concat :data="homeData.concat"/>

        
        <Message :data="homeData.message"/>
        
        
        <Foot/>
        
    </div>
</template>
<script>
import Head from '@/components/common/VideoHeader/Mobile.vue'
import Service from '@/components/home/service/Mobile.vue'
import Process from '@/components/home/Process/Mobile.vue'
import Feature from '@/components/home/Feature/Mobile.vue'
import Message from '@/components/home/Message/Mobile.vue'
import Concat from '@/components/home/Concat/Mobile.vue'
import Cases from '@/components/home/Cases/Mobile.vue'
import Employee from '@/components/home/Employee/Mobile.vue'

import Foot from '@/components/common/Foot/Mobile.vue'
import ModuleHead from '@/components/common/ModuleHead/Mobile.vue'
import Slogan from '@/components/common/Slogan/Mobile.vue'
import Menu from '@/components/common/Menu/Mobile.vue'


import homeData from '@/data/home.js'
export default {
    components:{
        Head,Slogan,Service,Process,Feature,Message,Concat,Cases,Employee,
        Foot,ModuleHead,Menu
    },
    data(){
        return {
            homeData,
        }
    }
}
</script>