<template>
    <div class="process">
        <div class="main">
            <div class="outer" v-for="(unit,j) in formatData" :key="j">
                <div :class="['line','line'+j]">
                    <div  v-for="(item,i) in unit" :key="i" :class="['square','bg-img',item.classMobile]" :style="{'background-image':'url('+item.img+')'}">
                        <div class="unit">
                            <div class="text">
                                <h1 class="fs16">{{item.title}}</h1>
                                <h2 class="fs14">{{item.subTitle}}</h2>
                            </div>
                            <p v-if="false" class="fs12">{{item.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:["data"],
    data(){
        return {
            formatData:null,
        }
    },
    created(){
        this.setFormatData();
    },
    methods:{
        //分组 2-1-2
        setFormatData(){
            let formatData=[[],[],[]];
            for(let i=0;i<this.data.length;i++){
                let idx=0;
                if(i<2){
                    idx=0;
                }else if(i==2){
                    idx=1;
                }else{
                    idx=2;
                    
                }
                formatData[idx].push(this.data[i]);
            };
            this.formatData=formatData;
        }
    }
}
</script>
<style scoped lang="less">
.process{
    .main{
        padding-top:50px;
        .outer{
            width:100%;
            overflow: auto;
            .line{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            .line0,.line2{
                width:500px;
            }
        }
        .square{
            height:200px;
            margin-bottom:10px;
            .unit{
                padding:10px;
                background:-webkit-linear-gradient(bottom,rgba(255,255,255,0),rgba(255,255,255,0.5));
                .text{
                    
                    padding:10px 10px;
                    display: inline-block;
                }
                h2{
                    padding:5px 0 0 0;
                    font-weight: normal;
                }
                p{
                    
                }
            }
        }
        .square-a{
            width:40%;
        }
        .square-b{
            width:calc(100% - 40% - 10px);
        }
        .square-c{
            width:100%;
        }
    }
}
</style>