<template>
    <div class="employee">
        <div class="left member">
            <div class="text right">
                <h3 class="fs20">刘暮云</h3>
                <h4 class="fs14">全端开发师</h4>
                <p class="main-txt">10年Web开发经验，精通前后端主流语言及框架。</p>
            </div>
            <div class="photo">
                <img :src="bgPicture"/>
            </div>
        </div>
        <div class="center">
            <h1  class="fs30">骨干</h1>
            <p  class="fs20">Backbone</p>
            <p class="main-txt">10年以上行业经验，参与过多个大型网络项目开发。</p>
        </div>
        <div class="right member">
            <div class="photo">
                <img :src="bgPicture"/>
            </div>
            <div class="text">
                <h3>刘暮云</h3>
                <h4>全端开发师</h4>
                <p>10年Web开发经验，精通前后端主流语言及框架。</p>
            </div>
            
        </div>
    </div>
</template>
<script>
import bgPicture from '@/assets/home/head.jpg'
export default {
    data(){
        return {
            bgPicture
        }
    }
}
</script>
<style scoped lang="less">
.employee{
    padding:50px 0;
    display: flex;
    .center{
        text-align: center;
        padding:30px 50px;
    }
    
    .member{
        width:40%;
        display: flex;
        .photo{
            width:40%;
            img{
                width:100%;
                height: auto;
            }
        }
        .text{
            flex:1;
            padding:30px;
            
            h4{
                font-weight: normal;
                margin:5px 0 15px 0;
            }
        }
        .right{
            text-align: right;
        }
    }
    .center{
        flex:1;
    }
}
</style>